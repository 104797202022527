import {
	getBuyerProjectSeller,
	tracking as barkTrackingLogEvent,
	_t,
} from '@bark/bark-core';
import moment from 'moment';

export default async function SlfPublicProfilePage() {
	/**
	 * Window.Bark global
	 * @type {window.Bark}
	 */
	let Bark = (window.Bark = window.Bark || {});

	$(document).ready(function () {
		window?.Bark?.triggerHotjarRecording('slf_page');
		console.log('Hotjar event triggered: slf_page');

		$('.js-slf-back-to-matches').removeClass('d-none');

		$('.js-slf-back-to-matches')
			.off('click.slf-back-to-matches')
			.on('click.slf-back-to-matches', (e) => {
				e.preventDefault();
				window.location.href = getBackToMatchesUrl();
			});

		if (window.location.href.indexOf('/slf/') != -1) {
			const sellerProfileId = $('#spidh-hv').val();
			const urlParams = new URLSearchParams(window.location.search);
			barkTrackingLogEvent('Seller_list_first', 'Seller Profile View', {
				sellerProfileId: sellerProfileId,
				uuid: urlParams.get('uuid'),
			});
		}

		$('.js-external-link')
			.off('click.external-link')
			.on('click.external-link', (e) => {
				barkTrackingLogEvent(
					'Seller_list_first',
					'Seller Profile - external link clicked',
					{
						sellerProfileId: $('#spidh-hv').val(),
						externalLink: e.target.href,
						uuid: QS.getValue('uuid'),
						country_id: window.Bark.ENV.ccid,
						searchID: QS.getValue('searchID'),
						location: QS.getValue('location'),
						locationLat: QS.getValue('locationLat'),
						locationLng: QS.getValue('locationLng'),
						timestamp: moment.utc().toDate(),
						local_timestamp: moment.utc().local().toDate(),
						filters_applied: QS.getValue('filtersApplied'),
						sorting_applied: QS.getValue('sortingApplied'),
					},
				);
			});
	});

	const getBackToMatchesUrl = () => {
		const { web_hostname, lang, locale } = Bark.ENV;
		const url = new URL(window.location);
		const searchParams = url.searchParams;
		const categorySlug = searchParams.get('category_slug');
		const uuid = searchParams.get('uuid');

		return `https://${web_hostname}/${lang}/${locale}/m/${categorySlug}/?uuid=${uuid}`;
	};
}
