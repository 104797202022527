import { datadogRum } from '@datadog/browser-rum';
import { safeUrl } from '../legacy-scripts/barklib';

function getEnvFromLocation(hostname) {
	const subEnvs = ['local', 'development', 'staging', 'qa'];

	return subEnvs.find((env) => hostname.includes(env)) ?? 'production';
}

export function initDataDogRum() {
	if (getEnvFromLocation(window.location.hostname) !== 'production') {
		console.log(
			'Datadog RUM is disabled in non-production environments, please turn it on if you need to',
		);

		return;
	}

	if (window?.Bark?.ENV?.site_page_type) {
		datadogRum.setGlobalContextProperty('pageType', window.Bark.ENV.site_page_type);
	}

	let overrideDDProps = {};
	const ddDefaultProps = {
		applicationId: 'd69f2a0a-022d-4157-81d2-02b3448230fa',
		clientToken: 'pub70fa5edd4137c9ef2b8b7e993ede926a',
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.eu',
		service: 'bark-web',
		env: getEnvFromLocation(window.location.hostname),
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 1,
		sessionReplaySampleRate: 1,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
		beforeSend: (event) => {
			if (event.view && event.view.url) {
				event.view.url = safeUrl(event.view.url);
			}
		},
	};
	switch (window.Bark.ENV.site_page_type) {
		case 'SLF Landing Page':
			overrideDDProps = {
				version: window?.SellerListFirstApp?.getVersion(),
				sessionSampleRate: 100,
				sessionReplaySampleRate: 100,
			};
			break;
		case 'SLF Company Page':
			overrideDDProps = {
				version: window?.SellerListFirstApp?.getVersion(),
				sessionSampleRate: 100,
				sessionReplaySampleRate: 100,
			};
			break;
		default:
			overrideDDProps = {}
	}

	datadogRum.init({ ...ddDefaultProps, ...overrideDDProps });
}
